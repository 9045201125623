module.exports = {
    'titlemain': 'Обучение<br/>и стажировка<br/>за границей',
    'langschool': 'Описание школы',
    'home': 'Проживание',
    'food': 'Питание',
    'prog': 'Программа экскурсий:',
    'progincl': 'Программа включает:',
    'doppay': 'Дополнительно оплачивается:',
    'photonon': 'Тут скоро появятся фото с наших поездок...',
    'buttonaddz': 'Записаться',
    'buttonconslt': 'Получить консультацию',
    'buttonpronas': 'О нас',
    'buttonshare': 'Поделиться',
    'per': 'Период:',
    'chat-text-1': 'Hello! Есть вопросы?',
    'chat-text-2': 'Пиши нам в любой из мессенджеров'
    


};