module.exports = {
    'titlemain': 'Навчання<br/>та стажування<br/>за кордоном',
    'langschool': 'Опис школи',
    'home': 'Проживання',
    'food': 'Харчування',
    'prog': 'Програма екскурсій:',
    'progincl': 'Пакетна програма включає:',
    'doppay': 'Додатково оплачується:',
    'photonon': 'Тут скоро будуть фотографії з наших поїздок...',
    'buttonaddz': 'Записатись',
    'buttonconslt': 'Отримати консультацію',
    'buttonpronas': 'Про нас',
    'buttonshare': 'Поділитись',
    'per': 'Період:',
    'chat-text-1': 'Hello! Є питання?',
    'chat-text-2': 'Пишіть нам у будь-який з мессенджерів'
};